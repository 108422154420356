<template>
     <TabBar>
        <TabBarItem link="/workBench">
            <img slot="icon" src="~assets/image/tabbar/home.png" alt="">
            <img slot="active-icon" src="~assets/image/tabbar/home_active.png" alt="">
            <div slot="text">工作台</div>
        </TabBarItem>
         <TabBarItem link="/staffCheck" v-if="isvalidRole">
            <img slot="icon" src="~assets/image/tabbar/remark.png" alt="">
            <img slot="active-icon" src="~assets/image/tabbar/remark_active.png" alt="">
            <div slot="text">员工点评</div>
        </TabBarItem>
        <TabBarItem link="/main">
            <img slot="icon" src="~assets/image/tabbar/mine.png" alt="">
            <img slot="active-icon" src="~assets/image/tabbar/mine_active.png" alt="">
            <div slot="text">我的点评</div>
        </TabBarItem> 
    </TabBar> 
    
</template>

<script>
import TabBar from 'components/tabBar/TabBar.vue'
 import TabBarItem from 'components/tabBar/TabBarItem.vue'
 export default{
        name:'MainTabBar',
        components:{
            TabBar,
          TabBarItem
        },
        props:{
            isvalidRole:{
                type:Boolean,
                default:false
            }
        }
    }
</script>

