<template>
  <div class="backg">
    <div class="userName">用户 :  {{userName}}</div>
    <div class="bench">
        <div class="messageToday">
          <p>{{waitRemarkNum+waitApproveNum+perforApproveNum+selfApproveNum}}</p>
          <!-- <div>
            <div class="rightLDiv">
              <img src="~assets/image/home/up.png" alt="">
              <span>12.09%</span>
            </div>
            <div class="rightBT">今日事件总数</div>
          </div> -->
        </div> 
        <div class="checkList">
            <div class="timeDiv">
              <p class="timeTitle">任务概览</p>
              <p class="time">{{todayTime}}</p>
            </div>
            <div class="checkDiv" @click="toStaffCheck(0)" v-if="waitRemark">
              
                  <div class="leftDiv">
                    <img src="~assets/image/home/check_1.png" alt="" class="checkImg">
                      <span>待点评</span>
                  </div>
                  <div class="rightDiv">
                      <span class="propleNum">{{waitRemarkNum}}人</span>
                      <img src="~assets/image/home/right.png" alt="" class="right">
                  </div>
              </div>
             <div class="checkDiv"  @click="toStaffCheck(1)" v-if="waitApprove">
                <div class="leftDiv">
                      <img src="~assets/image/home/check_2.png" alt="" class="checkImg">
                      <span>待签核</span>
                </div>
                <div class="rightDiv">
                    <span class="propleNum">{{waitApproveNum}}人</span>
                    <img src="~assets/image/home/right.png" alt=""  class="right">  
                </div>  
            </div>
             <div class="checkDiv"  @click="toStaffCheck(2)">
                <div class="leftDiv">
                        <img src="~assets/image/home/check_3.png" alt="" class="checkImg">
                        <span>考核完成</span>
                </div>
                <div class="rightDiv">
                          <span class="propleNum">{{perforApproveNum}}人</span>
                          <img src="~assets/image/home/right.png" alt=""  class="right">
                </div>
               
            </div>
            <div class="checkDiv"  @click="toStaffCheck(3)" v-if="selfApprove">
                <div class="leftDiv">
                        <img src="~assets/image/home/check_4.png" alt="" class="checkImg">
                        <span>待自评</span>
                </div>
                <div class="rightDiv">
                          <span class="propleNum">{{selfApproveNum}}人</span>
                          <img src="~assets/image/home/right.png" alt=""  class="right">
                </div>
               
            </div>
        </div>
      </div>
    
  </div>
    
</template>
<script>
import {countTask} from '../../network/normal'

export default {
  name: 'WorkBench',
  data(){
    return {
        waitRemark:false,
        waitRemarkNum:0,
        waitApprove:false,
        waitApproveNum:0,
        perforApproveNum:0,
        selfApproveNum:0,
        selfApprove:false,
        userName:''
    }
  },
  created(){
    this.isShowRemarkOrApprove();
    this.userName = localStorage.getItem('username');
  },
  computed:{
    todayTime(){
      let myDate = new Date();
      myDate = myDate.toLocaleDateString()
      return myDate;
    }
  

  },
  mounted(){
    document.querySelector('body').setAttribute('style','background-color:#EEEEEE')
    
  },
  beforeDestroy(){
    document.querySelector('body').removeAttribute('style')
  },
  methods:{
    toStaffCheck(num){
      this.$router.push({
        name:'CheckList',
        query:{
          state:num
        }
      })
    },
    isShowRemarkOrApprove(){
          this.$toast.loading({
                message: '努力加载中...',
                forbidClick: false,
                duration:0
            });
        countTask().then((res)=>{
          this.$toast.clear();
            if(res.success){
                for (const item of res.data) {
                  if (item.remark == '待点评') {
                    this.waitRemark = true;
                    this.waitRemarkNum = item.num
                  }else if(item.remark == '考核完成'){
                    this.perforApproveNum = item.num
                  }else if(item.remark == '待签核'){
                    this.waitApproveNum = item.num
                    this.waitApprove = true
                  }
                   else if(item.remark == '待自评'){
                    this.selfApproveNum = item.num 
                    this.selfApprove = true
                  }
                }
            }else{
               this.$toast.fail('请填写意见后进行驳回');
            }
        })
    }
  }
 
}
</script>
<style scoped>
.backg{
  width: 100%;
  height: 100%;
  background-color: '#EEEEEE';
  position: relative;
}
 .bench{
   background-image: url('~assets/image/home/background.png');
   background-repeat: round;
   height: 720px;
   padding-top: 180px;
 }
 .userName{
  color: #EEEEEE;
  position: absolute;
  right: 20px;
  top:20px
 }
 .messageToday{
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
 }
 .messageToday p{
     font-size: 120px;
    font-weight: bold;
    color: #FFFFFF;
    padding-right: 23px;
 }
 .rightLDiv{
   width: 160px;
height: 40px;
background: #188DDF;
border-radius: 20px;

 }
 .rightLDiv img{
   width: 18px;
   height: 13px;
   
   margin-right: 13px;
 }
 .rightLDiv span{
  height: 19px;
  font-size: 24px;
  font-weight: 500;
  color: #FEFEFE;
  line-height: 40px;
 }
 .checkList{
   width: 702px;
   height: 800px;
   margin: 174px auto;
   background-color: #ffffff;
    border-radius: 10px;
 }
 .rightBT{
    height: 23px;
    font-size: 24px;
    font-weight: 500;
    color: #FEFEFE;
    margin-top: 16px;
 }
 .timeDiv{
   text-align: center;
   display: flex;
   flex-direction: column;
   width: 100%;
  

 }
 .timeTitle{
  font-size: 36px;
  font-weight: 500;
  color: #333333;
   margin-top: 68px;
 }
 .time{
  font-size: 24px;
  font-weight: 500;
  color: #999999;
  margin-top: 16px;
 }
 .checkImg{
   width: 120px;
   vertical-align:middle;
 }
 .right{
    width: 17px;
 }
 .checkDiv{
   display: flex;
   justify-content: space-between;
   
   height: 179px;
   align-items: center;
   border-bottom: solid 1px #EEEEEE;
 }
 .propleNum{
  font-size: 24px;
  font-weight: 500;
  color: #FF943F;
  margin-right: 25px;
 
 }
 .leftDiv{
   margin-left:70px;

 
 }
 .leftDiv span{
 
font-size: 30px;
font-family: PingFang;
font-weight: 500;
color: #333333;
padding-left: 31px;

 }
 .rightDiv{
   margin-right: 70px;
 }
</style>
