<template>
    <div id="TabBarItem" @click="itemClick()">
        <div class="item-icon" v-show="!isActive"><slot name="icon"></slot></div>
        <div class="item-active-icon" v-show="isActive"><slot name="active-icon"></slot></div>
        <div class="item-text" :style="activeStyle"><slot name="text"></slot></div>
    </div>
</template>
<script>
    export default{
        name:'TabBarItem',
        props:{
            link:{
                type:String,
                required:true
            }
        },
        computed:{
            isActive(){
                
                return this.$route.path.indexOf(this.link) !== -1
            },
            activeStyle(){
                return this.isActive ? {'color':'#2998F8'}:{}
            }
        },
        methods:{
            itemClick(){
                this.$router.replace(this.link)
            }
        }
    }
</script>
<style scoped>
 #TabBarItem {
    flex: 1;
  }

  .item-icon img, .item-active-icon img {
    width: 50px;
    height: 48px;
    margin-top: 8px;
    vertical-align: middle;
  }

  .item-text {
    font-size: 24px;
    margin-top: 10px;
    color: #333;
  }
</style>
