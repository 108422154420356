<template>
  <div id="app">
  
     <router-view/>
    <MainTabBar v-if="tabType" :isvalidRole="isvalidRole"></MainTabBar>
  </div>
</template>
<script>
import MainTabBar from 'components/mainTabBar/MainTabBar.vue'

export default{
  name:'App',
  data(){
    return{
      tabType:true,
      isvalidRole:true
    }
  },
  components:{
    MainTabBar
  },
 
  watch:{
      $route(e){
        if(e.name == 'CheckList' || e.name == 'CheckMessage' || e.name == 'CheckDetail' || e.name == 'Login' || e.name == 'SuggestMessage'){
            this.tabType = false
        }else{
          this.tabType = true
        }
      }
  }

}

</script>
<style>
@import "assets/css/base.css";
  #app{
    position: relative;
  
  }
</style>
