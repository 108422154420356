import axios from "./axios";

//首页 统计数量
export function countTask(){
    return axios({
        url:'api/assessmenttask/countTask'
    })
}
//获取待办列表
//0-待点评 1-代签核 2-已完成 3-待自评
export function getList(state,username='',account='',pageNum=1,pageSize=20){
    return axios({
        url:'api/assessmenttask/list',
        params:{
            state,
            pageNum,
            pageSize,
            username,
            account
        }
    })
}
//获取详细数据
export function getTaskDetail(taskId){
    return axios({
        url:'api/assessmenttask/task',
        params:{
            taskId
        }
    })
}
//题库和分数
export function getQuestion(taskId,directionId){
    return axios({
        url:'api/assessmenttask/question',
        params:{
            taskId,
            directionId
        }
    })
}
//保存做答分数
export function saveAnswer(){
    let arr = arguments[0]

    return axios({
        url:'api/assessmenttask/saveAnswer',
        method:'post',
        data:arr
    })
}
//保存评价建议
export function saveRemark(remark,taskId){
    return axios({
        url:'api/assessmenttask/saveRemark',
        method:'post',
        data:{
            remark,
            taskId
        }
    })
}
//驳回评价理由
export function refuseRemark(remark,taskId){
    return axios({
        url:'/api/assessmenttask/refuse',
        method:'post',
        data:{
            remark,
            taskId
        }
    })
}
//获取评价标签
export function getRemarkTag(pageNum=1,pageSize=20){
    return axios({
        url:'/api/assessmenttask/word',
        data:{
            pageNum,
            pageSize
        }
    })
}
//加载评价建议
export function getRemark(taskId){
    return axios({
        url:'api/assessmenttask/getRemark',
        params:{
            taskId
        }
    })
}
//获取个人信息
export function getPersonMessage (){
    return axios({
        url:'api/assessmenttask/person',
    })
}