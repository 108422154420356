import Vue from 'vue'
import VueRouter from 'vue-router'
import WorkBench from '../views/workbench/WorkBench.vue'

const Main = ()=>import('../views/main/Main.vue')
const StaffCheck = ()=>import('../views/staffcheck/StaffCheck.vue')
const CheckList = ()=>import('../views/checkList/CheckList.vue')
const CheckMessage = ()=>import('../views/checkMessage/CheckMessage.vue')
const checkDetail = ()=>import('../views/checkDetail/checkDetail.vue')
const Login =() => import('../views/login/Login.vue')
const SuggestMessage = () => import('../views/suggestMessage/SuggestMessage.vue')

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/',
    redirect: WorkBench
  },
  {
    path: '/workBench',
    name: 'WorkBench',
    component: WorkBench
  },
  {
    path: '/main',
    name:'Main',
    component: Main
  },
  {
    path: '/staffCheck',
    name: 'StaffCheck',
    component: StaffCheck
  },
  {
    path:'/checkList',
    name:'CheckList',
    component:CheckList,
    children:[
      {
        path:'checkMessage',
        name:'CheckMessage',
        component:CheckMessage,
        children:[
          {
            path:'checkDetail/:id/:maxScore/:weight/:title',
            name:'CheckDetail',
            component:checkDetail
          },
          {
            path:'checkDetail/suggestMessage',
            name:'SuggestMessage',
            component:SuggestMessage
          }
        ]
      }
    ]
  },
  {
    path:'/login',
    name:'Login',
    component:Login
  }
]



const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token')
  if(token){
    next();
  }else{
    //如果是登录页面路径，就直接next()
    if (to.path === '/login') {
      next();
    } else {
      next('/login');
    }
  }
})



export default router
